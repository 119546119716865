import {
	Message
} from 'element-ui'
import {
	handlePlayerClassifySave,
	handlePagePlayerClassify,
	handleDeletePlayerClassify,
	handleMyPlayerClassify,
	handlePageQueryPlayers,
	handleDistinguishCardNo,
	handleSavePlayer,
	handleDeletePlayers,
	handleBatchPlayersClassify,
	handlePlayerDetails,
	handleBatchImportPlayers,
	handlePlayerClassifyDetails,
	handleDownloadPlayers,
	handlePageQueryPrePlayers,
	handleDeletePrePrePlayers,
	handleStatisticsWaitImportPrePlayers,
	handleImportPrePlayers
} from '@index/api/team/playerinfo';

export default {
	name: 'teamplayerinfo',
	components: {

	},
	data() {
		return {
			addModifyPlayerDialogTitle: '新增选手',
			realNameAuthStatuses: ["未认证", "已认证", "认证失败"],
			countWaitImportPrePlayers: 0,
			selectedWaitImportPrePlayerIds: [],
			prePlayersList: [],
			prePlayerQueryCon: '',
			pageSizePrePlayers: 30,
			currPagePrePlayers: 1,
			totalCountPrePlayers: 0,
			userId: sessionStorage.getItem('loginUserId'),
			batchImportPlayers: [],
			groupForm: {},
			groupDialogVisible: false,
			selectedPlayerIds: [],
			selectedPlayerCount: 0,
			showPlayerFormErrorTips: false,
			currTabs: "player",
			addClassifyDialogVisible: false,
			classifyForm: { classifyName: '' },
			playerForm: {
				sex: '',
				birthday: '',
				cardType: '0',
				cardNO: '',
			},
			playerQueryForm: {
				realNameAuthStatus: ""
			},
			parentImportForm: {},
			importForm: {},
			forbidInput: false,
			addPlayerDialogVisible: false,
			importPlayerDialogVisible: false,
			commonDialogVisible: false,
			batchImportPlayerDialogVisible: false,
			allPlayersClassifys: [],
			playerstableData: [],
			headers: {
				'Authorization': sessionStorage.getItem('token')
			},
			uploadFileParams: {
				classifyId: ''
			},
			path: process.env.VUE_APP_BASE_API + "player/uploadPlayerCheckup",
			pageSize1: 30,
			currPage1: 1,
			totalCount1: 0,
			pageSize2: 30,
			currPage2: 1,
			totalCount2: 0,
			classifyData: [],
			playerFormRules: {
				name: [{
					required: true,
					message: '请输入选手姓名',
					trigger: 'blur'
				}],
				cardType: [{
					required: true,
					message: '请选择证件类型',
					trigger: 'blur'
				}],
				cardNO: [{
					required: true,
					message: '请输入证件号码',
					trigger: 'blur'
				}]				
			},
			dialogPlayerTitle: '新增分类',
			currPageIndex: '0',
		};
	},
	watch: {

	},
	created() {
		this.myPlayerClassify(); //加载分类
		this.pageQueryPlayers(); //查询选手
		this.statisticsWaitImportPrePlayers();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange1(pageSize) { //改变页面大小
			this.pageSize1 = pageSize;
			this.pageQueryPlayers();
		},
		handleCurrentChange1(currPage) { //跳转到某一页
			this.currPage1 = currPage;
			this.pageQueryPlayers();
		},
		handleSizeChange2(pageSize) { //改变页面大小
			this.pageSize2 = pageSize;
			this.pagePlayerClassify();
		},
		handleCurrentChange2(currPage) { //跳转到某一页
			this.currPage2 = currPage;
			this.pagePlayerClassify();
		},
		fastQueryPlayer() { //快速查询选手
			this.currPage1 = 1;
			this.pageQueryPlayers();
		},
		playerClassifySave() { //保存选手分类
			handlePlayerClassifySave(this.classifyForm).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.addClassifyDialogVisible = false;
					this.currPage2 = 1;
					this.pagePlayerClassify();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		pagePlayerClassify() { //分页查询选手分类
			handlePagePlayerClassify({
				pageSize: this.pageSize2,
				currPage: this.currPage2,
				con: "",
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					// 重新复制选手分类为最新
					this.allPlayersClassifys = res.data;
					this.classifyData = res.data;
					this.currPage2 = res.currPage;
					this.pageSize2 = res.pageSize;
					this.totalCount2 = res.totalCount;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		deletePlayerClassify(id) { //删除分类
			this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				confirmButtonClass: 'confirmButtonClass',
				type: 'warning'
			}).then(() => {
				handleDeletePlayerClassify(id).then(res => {
					if (res.status == 200) {
						Message({
							message: res.msg,
							type: 'success',
							duration: 5 * 1000
						})
						this.pagePlayerClassify();
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}
				})
			});

		},
		// modify() {
		//   this.commonDialogVisible = true;
		// },
		downloadFile() {
			const link = document.createElement('a')
			// download属性
			link.setAttribute('download', "选手信息.xls ")
			// href链接
			link.setAttribute('href',
				"https://score2021.oss-cn-beijing.aliyuncs.com/template/%E9%80%89%E6%89%8B%E4%BF%A1%E6%81%AF.xls")
			// 自执行点击事件
			link.click()
			document.body.removeChild(link)
		},
		switchTabs(tab) {
			switch (tab.name) {
				case "player":
					break;
				case "classify":
					this.pagePlayerClassify();
					break;
			}
		},
		reloadPlayerClassify() {
			this.currPage2 = 1;
			this.pagePlayerClassify();
		},
		myPlayerClassify() {
			handleMyPlayerClassify({

			}).then(res => {
				if (res.status == 200) {
					this.allPlayersClassifys = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		selectLeftMenu(classifyId) {
			this.currPage1 = 1;
			//  this.playerQueryForm = {};
			this.playerQueryForm.classifyId = classifyId;
			this.pageQueryPlayers();
		},
		pageQueryPlayers() {
			handlePageQueryPlayers({
				pageSize: this.pageSize1,
				currPage: this.currPage1,
				con: this.playerQueryForm.con,
				realNameAuthStatus: this.playerQueryForm.realNameAuthStatus,
				classifyId: this.playerQueryForm.classifyId,
				sortWay: [{
					fieldName: 's.createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					var tempArr=[];
					// for(var i=0;i<100;i++){
					// 	tempArr=tempArr.concat(res.data)
					// }
					tempArr=res.data;
					this.playerstableData = tempArr ;
					this.currPage1 = res.currPage;
					this.pageSize1 = res.pageSize;
					this.totalCount1 = res.totalCount;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		savePlayer() { //新增选手
			this.$refs.playerForm.validate((valid) => {
				if (valid) {
					handleSavePlayer(this.playerForm).then(res => {
						if (res.status == 200) {
							Message({
								message: res.msg,
								type: 'success',
								duration: 5 * 1000
							})
							this.addPlayerDialogVisible = false;
							this.currPage1 = 1;
							this.playerQueryForm = {realNameAuthStatus:''};
							this.pageQueryPlayers(); //查询选手
						} else {
							Message({
								message: res.msg,
								type: 'error',
								duration: 5 * 1000
							})
						}
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
			return;

		},
		selectCardType(value) { //选择证件类型
			if ('0' == value) {
				this.forbidInput = true;
			} else {
				this.forbidInput = false;
			}
			this.playerForm.cardNO = "";
			this.playerForm.sex = "";
			this.playerForm.birthday = "";

		},
		distinguishCardNo() { //身份证自动识别
			var cardNO = this.playerForm.cardNO;
			this.showPlayerFormErrorTips = false;
			if (this.playerForm.cardType != '0') {
				return;
			}
			this.playerForm.sex = "";
			this.playerForm.birthday = "";
			if (!(cardNO && (cardNO.length == 15 || cardNO.length == 18))) {
				return;
			}

			handleDistinguishCardNo(cardNO).then(res => {
				if (res.status == 200) {
					this.playerForm.sex = res.data.sex;
					this.playerForm.birthday = res.data.birthday;
					this.showPlayerFormErrorTips = false;

				} else {
					this.playerForm.sex = "";
					this.playerForm.birthday = "";
					this.showPlayerFormErrorTips = true;

				}
			});
		},
		selectPlayers(selection) {
			this.selectedPlayerCount = selection.length;
			this.selectedPlayerIds = [];
			selection.forEach((elem, index) => {
				console.log(elem, index);
				this.selectedPlayerIds.push(elem.id);
			});

		},
		deletePlayer(id) { //删除单个选手
			this.deletePlayerRequest([id]);
		},
		deletePlayers() { //批量删除选手
			this.deletePlayerRequest(this.selectedPlayerIds);
		},
		deletePlayerRequest(ids) { //删除选手请求
			this.$confirm('此操作将永久删除选手, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				confirmButtonClass: 'confirmButtonClass',
				type: 'warning'
			}).then(() => {
				handleDeletePlayers({
					idList: ids
				}).then(res => {
					if (res.status == 200) {
						Message({
							message: res.msg,
							type: 'success',
							duration: 5 * 1000
						})
						this.pageQueryPlayers();
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}
				})
			});
		},
		moveGroup() { //移入分组
			if (!this.selectedPlayerIds || this.selectedPlayerIds.length <= 0) {
				Message({
					message: '请选择参赛选手!',
					type: 'error',
					duration: 5 * 1000
				})
			} else {
				this.myPlayerClassify(); //加载分类  
				this.groupDialogVisible = true;
			}
		},
		batchPlayersClassify() { //批量更新选手分类
			handleBatchPlayersClassify({
				playerIds: this.selectedPlayerIds,
				classifyId: this.groupForm.id
			}).then(res => {
				if (res.status == 200) {
					this.groupDialogVisible = false;
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.playerQueryForm = {realNameAuthStatus:''};
					this.pageQueryPlayers();

				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		playerDetails(id) { //选手详情
			this.addPlayerDialogVisible = true;
			this.addModifyPlayerDialogTitle = "修改选手";
			this.myPlayerClassify(); //加载分类

			handlePlayerDetails(id).then(res => {
				if (res.status == 200) {
					this.playerForm = res.data;
					if (res.data.cardType == '0') { //身份证--禁用出生年月日 和性别
						this.forbidInput = true;
					}

				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		uploadSuccess(res) { //上传成功

			if (res.status == 200) {
				this.batchImportPlayers = res.data;
			} else {
				Message({
					message: res.msg,
					type: 'error',
					duration: 5 * 1000
				})
			}
			this.$refs.upload.clearFiles();

		},
		selectImportClassify(classifyId) {
			this.uploadFileParams.classifyId = classifyId;
			this.importForm.classifyId = classifyId;
			if (this.batchImportPlayers && this.batchImportPlayers.length > 0) {
				if (!classifyId) {
					this.batchImportPlayers.forEach((elem2) => {
						elem2.classifyId = "";
						elem2.classifyName = "";
					})
				} else {
					this.allPlayersClassifys.forEach((elem) => {
						if (elem.id == classifyId) {
							this.batchImportPlayers.forEach((elem2) => {
								elem2.classifyId = elem.id;
								elem2.classifyName = elem.classifyName;
							})
							return;
						}
					});
				}

			}

		},
		batchImportPlayersRequest() { //批量导入选手
			if (!this.batchImportPlayers) {
				Message({
					message: '选手不能为空!',
					type: 'error',
					duration: 5 * 1000
				})
				return;
			}
			var list = new Array();
			this.batchImportPlayers.forEach((elem) => {
				if (!elem.tips) {
					list.push(elem);
				}
			})
			if (!list || list.length <= 0) {
				Message({
					message: '没有合法的选手!',
					type: 'error',
					duration: 5 * 1000
				})
				return;
			}

			handleBatchImportPlayers({
				list: list
			}).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.batchImportPlayerDialogVisible = false;
					this.currPage1 = 1;
					this.playerQueryForm = {realNameAuthStatus:''};
				//	this.pageQueryPlayers(); //查询选手
					location.reload();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		playerClassifyDetails(id) { //选手分类详情
			this.addClassifyDialogVisible = true;
			if (id) {
				this.dialogPlayerTitle = '修改分类'
				handlePlayerClassifyDetails(id).then(res => {
					if (res.status == 200) {
						this.classifyForm = res.data;
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}

				});
			} else {
				this.dialogPlayerTitle = '新增分类';
				this.classifyForm.classifyName = ''
			}
		},
		openAddPlayerDialog() { //打开新增选手对话框
			this.addPlayerDialogVisible = true;
			this.addModifyPlayerDialogTitle = "新增选手";
			this.myPlayerClassify(); //加载分类
			this.playerForm = {
				sex: '',
				birthday: '',
				cardType: '0',
				cardNO: '',
			};
		},
		openDatchImportPlayerDialog() { //打开批量上传选手对话
			this.batchImportPlayerDialogVisible = true;
			this.batchImportPlayers = [];
			this.uploadFileParams.classifyId = '';
		},
		deleteWaitUploadPlayer(index) { //删除待上传选手
			var temp = [];
			this.batchImportPlayers.forEach((item) => {
				if (this.batchImportPlayers[index] != item) {
					temp.push(item);
				}
			});
			this.batchImportPlayers = temp;
		},
		downloadPlayers() { //现在选手
			handleDownloadPlayers(this.playerQueryForm).then(res => {
				var fileName = "选手.xlsx";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值
					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {

					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});

		},
		handleSizeChangePrePlayers(pageSize) { //改变页面大小
			this.pageSizePrePlayers = pageSize;
			this.pageQueryPlayers();
		},
		handleCurrentChangePrePlayers(currPage) { //跳转到某一页
			this.currPagePrePlayers = currPage;
			this.pageQueryPlayers();
		},
		fastPrePlayers() {
			this.currPagePrePlayers = 1;
			this.pageQueryPrePlayers();
		},
		pageQueryPrePlayers() { //分页查询准选手
			handlePageQueryPrePlayers({
				pageSize: this.pageSizePrePlayers,
				currPage: this.currPagePrePlayers,
				con: this.prePlayerQueryCon,
				userId: this.userId
			}).then(res => {
				if (res.status == 200) {
					this.prePlayersList = res.data;
					this.currPagePrePlayers = res.currPage;
					this.pageSizePrePlayers = res.pageSize;
					this.totalCountPrePlayers = res.totalCount;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}

			})
		},
		openImportPlayerDialog() { //打开家长导入选手对话框
			this.importPlayerDialogVisible = true;
			this.pageQueryPrePlayers();
			
		},
		selectPrePlayers(selection) {
			console.log(">>selection>>>>>" + JSON.stringify(selection));
			this.selectedWaitImportPrePlayerIds = [];
			selection.forEach(item => {
				this.selectedWaitImportPrePlayerIds.push(item.id);
			})


		},
		deletepPrePlayer(id) {
			handleDeletePrePrePlayers({
				idList: [id]
			}).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.pageQueryPrePlayers();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});

		},
		statisticsWaitImportPrePlayers() { //统计待导入选手
			handleStatisticsWaitImportPrePlayers().then(res => {
				if (res.status == 200) {
					this.countWaitImportPrePlayers = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		importPlayerEvent() { //导入准选手
			if (!this.selectedWaitImportPrePlayerIds || this.selectedWaitImportPrePlayerIds.length <= 0) {
				Message({
					message: '请选择数据!',
					type: 'error',
					duration: 5 * 1000
				})
				return;
			}
			handleImportPrePlayers({
				idList: this.selectedWaitImportPrePlayerIds
			}).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.playerQueryForm = {realNameAuthStatus:''};
					this.fastQueryPlayer();
					this.importPlayerDialogVisible=false;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		closeImportPlayerDialog() {
			this.importPlayerDialogVisible = false;
			this.fastQueryPlayer();
			
		},
		toHelp() {
			window.open('help.html')
		},
		selectPage(index) {
			this.currPageIndex = index;
			switch ((index + "")) {
				case "0":
					break;
				case "1":
					this.pagePlayerClassify();
					break;
			}
		},
	}
}